import "./new.css";
import Sidebar from "../../pages/Sidebar/Sidebar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashnavbar from "../DashNav/Dashnavbar";
import Dashcopy from "../Dashcopy/Dashcopy";

const NewVendor = () => {
    const navigate = useNavigate();
    const [vendor, setVendor] = useState({
        vendorName: "",
        vendorDescription: "",
        contactEmail: "",
        contactPhone: "",
        addressStreet: "",
        addressCity: "",
        addressState: "",
    });

    let baseUrl;
    if (process.env.NODE_ENV === "development") {
        baseUrl = process.env.REACT_APP_BACKEND_LOCALAPI;
    } else {
        baseUrl = process.env.REACT_APP_BACKEND_LIVEAPI;
    }

    const handleChange = (e) => {
        setVendor({ ...vendor, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = `${baseUrl}/vendors`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(vendor),
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            navigate("/dashboard/vendors");
            setVendor({
                vendorName: "",
                vendorDescription: "",
                contactEmail: "",
                contactPhone: "",
                addressStreet: "",
                addressCity: "",
                addressState: "",
            });
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Dashnavbar />
                <div className="top">
                    <h1 className="text-2xl font-roboto tracking-wide">
                        <i className="fa-solid fa-store"></i> <span>Add New Vendor</span>
                    </h1>
                </div>
                <div className="bottom">
                    <div className="right">
                        <div className="container w-full">
                            <form
                                onSubmit={handleSubmit}
                                className="ml-5 mt-10 w-full p-6 rounded"
                            >
                                <div className="flex flex-wrap gap-0 w-full">
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="vendorName">
                                            Vendor Name
                                        </label>
                                        <input
                                            required
                                            onChange={handleChange}
                                            id="vendorName"
                                            name="vendorName"
                                            value={vendor.vendorName}
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Vendor Name"
                                        />
                                    </div>
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="vendorDescription">
                                            Description
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            id="vendorDescription"
                                            name="vendorDescription"
                                            value={vendor.vendorDescription}
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Description"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-0 w-full mt-3">
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="contactEmail">
                                            Contact Email
                                        </label>
                                        <input
                                            required
                                            onChange={handleChange}
                                            id="contactEmail"
                                            name="contactEmail"
                                            value={vendor.contactEmail}
                                            className="w-90 rounded formInput mr-10"
                                            type="email"
                                            placeholder="Contact Email"
                                        />
                                    </div>
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="contactPhone">
                                            Contact Phone
                                        </label>
                                        <input
                                            required
                                            onChange={handleChange}
                                            id="contactPhone"
                                            name="contactPhone"
                                            value={vendor.contactPhone}
                                            className="w-90 rounded formInput mr-10"
                                            type="tel"
                                            placeholder="Contact Phone"
                                            pattern="[0-9]{10}"
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-0 w-full mt-3">
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="addressStreet">
                                            Address Street
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            id="addressStreet"
                                            name="addressStreet"
                                            value={vendor.addressStreet}
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Address Street"
                                        />
                                    </div>
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="addressCity">
                                            Address City
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            id="addressCity"
                                            name="addressCity"
                                            value={vendor.addressCity}
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Address City"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-0 w-full mt-3">
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="addressState">
                                            Address State
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            id="addressState"
                                            name="addressState"
                                            value={vendor.addressState}
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Address State"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end mt-0">
                                    <button
                                        type="submit"
                                        className="w-40 mr-8 bg-black hover:bg-logoClr text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Add Now
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center pb-2 mr-10 absolute bottom-0 right-10">
                    <Dashcopy />
                </div>
            </div>
        </div>
    );
};

export default NewVendor;
