import "./dtatable.css";
import { useState, useEffect, useContext, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../dataTableSource";
import { Link } from "react-router-dom";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { CustomerContext } from "../../context/customrContext";
import loadingGIf from "../../images/loading.gif";
import { debounce } from "../../context/debounce"; 
import { Search } from "lucide-react";
import { OrderContext } from "../../context/OrderContext";

const Datatable = () => {
  const { customerData } = useContext(CustomerContext);
  const { orderData } = useContext(OrderContext);

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(customerData);

  // Debounced filtering logic
  const handleSearch = useCallback(
    debounce((value) => {
      const filtered = customerData?.filter((customer) => {
        const username = customer.username?.toLowerCase() || "";
        const email = customer.email?.toLowerCase() || "";
        const mobileNumber = customer.mobileNumber || "";

        return (
          username.includes(value) ||
          email.includes(value) ||
          mobileNumber.includes(value)
        );
      });

      setFilteredData(filtered);
    }, 300), // 300ms delay
    [customerData]
  );

  // Handle search input
  const onSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    handleSearch(value); // Call the debounced function
  };

  // Reset filtered data if customerData changes
  useEffect(() => {
    setFilteredData(customerData);
  }, [customerData]);

  // Rows for DataGrid
  const rows = filteredData?.map((customer, index) => {
    const orders = orderData?.filter((order) => order.userId === customer._id) || [];
    const familyContacts = orders
      .map(
        (order, idx) =>
          `Order ${idx + 1}:\nOrder ID: ${order.orderId || "N/A"}\nPhone 1: ${
            order.phone1 || "N/A"
          }\nPhone 2: ${order.phone2 || "N/A"}`
      )
      .join("\n\n");
    return {
      id: index + 1,
      ...customer,
      familyContact: familyContacts || "No orders found",
    };
  });

  return (
    <div className="datatable font-serif text-sm">
      <div className="datatableTitle">
        <h2 className="text-xl tracking-wider leading-10 font-extraligh text-main">
          Customers List
        </h2>
        <Link to="/dashboard/customers/new" className="link">
          <AddCircleSharpIcon className="AddCircleSize" />
        </Link>
      </div>

      {/* Search bar */}
      <div className="relative w-96 mx-auto mb-6"> {/* Added margin-bottom */}
        <div className="absolute inset-y-0 m-2 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Find users by name, email, or contact"
          value={searchText}
          onChange={onSearchChange}
          className="
            w-full 
            pl-10 
            pr-4 
            py-2 
            border 
            border-gray-300 
            rounded-lg 
            focus:outline-none 
            focus:ring-2 
            focus:ring-blue-500 
            focus:border-transparent 
            transition-all 
            duration-300 
            text-sm
            shadow-sm
            hover:border-gray-400
          "
        />
      </div>

      {/* DataGrid */}
      {!filteredData || filteredData?.length === 0 ? (
        <div className="flex justify-center items-center">
          {customerData?.length === 0 ? (
            <p>No data available.</p>
          ) : (
            <img className="w-32 h-32" src={loadingGIf} alt="Loading" />
          )}
        </div>
      ) : (
        <DataGrid
          rowHeight={100}
          className="datagrid"
          rows={rows}
          columns={userColumns}
          pageSize={9}
          rowsPerPageOptions={[100]}
          checkboxSelection
        />
      )}
    </div>
  );
};

export default Datatable;
