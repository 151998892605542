import "../Customers/customers.css";
import Sidebar from "../Sidebar/Sidebar";
import DashNav from "../DashNav/Dashnavbar";
import VendorTable from "../Datatable/VendorTable";
import Dashcopy from "../Dashcopy/Dashcopy";

const Vendors = () => {
  return (
    <div className="list text-main">
      <Sidebar />
      <div className="listContainer">
        <DashNav />
        <VendorTable />
        <div className="flex justify-center  pb-2 mr-10 absolute bottom-0 center ml-20 ">
          <Dashcopy />
        </div>
      </div>
    </div>
  );
};

export default Vendors;
