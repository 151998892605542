import { height } from "@mui/system";

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "username",
    headerName: "Customer Name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "mobileNumber",
    headerName: "Phone",
    width: 150,
  },
  {
    field: "familyContact",
    headerName: "Family Contacts",
    width: 300,
    renderCell: (params) => (
      <div
        style={{
          maxHeight: "150px", // Limit height
          overflowY: "auto",  // Enable vertical scroll
          whiteSpace: "pre-wrap", // Preserve line breaks
          wordWrap: "break-word", // Wrap long lines
          padding: "8px", // Add padding for better readability
          border: "1px solid #ccc", // Optional: Add a border for clarity
          borderRadius: "4px", // Optional: Add rounded corners
          background: "#f9f9f9", // Optional: Background for better contrast
        }}
      >
        {params.value}
      </div>
    ),
  },
];
