export const vendorColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "vendorName",
    headerName: "Vendor Name",
    width: 120,
  },
  {
    field: "vendorDescription",
    headerName: "Description",
    width: 270,
  },
  {
    field: "contactEmail",
    headerName: "Email",
    width: 100,
  },
  {
    field: "contactPhone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "addressStreet",
    headerName: "Street Address",
    width: 120,
  },
  {
    field: "referralCode",
    headerName: "Referral Code",
    width: 150,
  },
];
