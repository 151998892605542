import "./dtatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { vendorColumns } from "../../vendorTableSource";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { VendorContext } from "../../context/vendorContext";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import loadingGIf from "../../images/loading.gif";

const VendorTable = () => {
    const { vendorData, setVendorData } = useContext(VendorContext);

    let baseUrl;
    if (process.env.NODE_ENV === "development") {
        baseUrl = process.env.REACT_APP_BACKEND_LOCALAPI;
    } else {
        baseUrl = process.env.REACT_APP_BACKEND_LIVEAPI;
    }

    // async function deleteVendor(id) {
    //     try {
    //         const response = await fetch(
    //             `${baseUrl}/vendor//${id}`,
    //             {
    //                 method: "DELETE",
    //             }
    //         );
    //         if (!response.ok) {
    //             throw new Error(`Failed to delete vendor with ID ${id}`);
    //         }
    //         console.log(`Vendor with ID ${id} deleted successfully`);
    //     } catch (error) {
    //         console.error("Error deleting vendor:", error);
    //     }
    //     const filterData = vendorData.filter((item) => item._id !== id);
    //     setVendorData(filterData);
    // }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        
                        <Link
                            to={`/dashboard/vendors/${params.row._id}/edit`}
                            style={{ textDecoration: "none" }}
                        >
                            <div className="updateButton">
                                <EditIcon />
                            </div>
                        </Link>
                        
                    </div>
                );
            },
        },
    ];

    return (
        <div className="datatable font-serif text-sm">
            <div className="datatableTitle">
                <h2 className="text-xl tracking-wider leading-10 font-extralight text-main">
                    Vendors List
                </h2>
                <Link to="/dashboard/vendors/new" className="link">
                    <AddCircleSharpIcon className="AddCircleSize" />
                </Link>
            </div>

            {!vendorData || vendorData.length === 0 ? (
                <div className="flex justify-center align-middle">
                    <img src="https://www.shutterstock.com/image-photo/funny-large-longhair-gray-kitten-600nw-1842198919.jpg" alt="show_empty" />
                </div>
            ) : (
                <DataGrid
                    rowHeight={100}
                    className="datagrid"
                    rows={vendorData.map((vendor, index) => ({
                        id: index + 1,
                        ...vendor,
                    }))}
                    columns={vendorColumns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[100]}
                    checkboxSelection
                />
            )}
        </div>
    );
};

export default VendorTable;
