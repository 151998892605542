import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PackageContext } from "../../context/packageContext";
import { CustomerContext } from "../../context/customrContext";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { format } from 'date-fns';
import axios from "axios";
import { OrderContext } from "../../context/OrderContext";

const SingleRow = () => {
  const [uidInputVisible, setUidInputVisible] = useState(false);
  const [uid, setUid] = useState("");
  const { packageData } = useContext(PackageContext);
  const { customerData } = useContext(CustomerContext);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const rowData = location.state.rowData;
  const order_id = rowData.orderId;

  useEffect(() => {
    setUid(rowData?.uid);
  }, [rowData]);

  let baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BACKEND_LOCALAPI : process.env.REACT_APP_BACKEND_LIVEAPI;

  const handleStatusChange = async (e) => {
    const clickedStatus = e.target.value;
    setSelectedStatus(clickedStatus);
    setUidInputVisible(clickedStatus === "Order Dispatched");

    try {
      const response = await axios.put(
        `${baseUrl}/order-status/${order_id}`,
        { orderStatus: clickedStatus, uid }
      );
      if (response.status === 200) {
        setSelectedStatus(response.data.updatedOrder.orderStatus);
        setUid(response.data.updatedOrder.uid);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const subTotal = rowData.subTotal;
  const totalDiscount = 0;
  const totalPrice = parseFloat(totalDiscount + subTotal / 1.18);
  const toalTax = ((totalPrice - totalDiscount) * 18) / 100;
  const formattedDate = format(new Date(rowData.createdAt), 'dd-MM-yyyy');

  if (!customerData || !packageData) return <Typography variant="body1">Loading...</Typography>;

  return (
    <div style={{ padding: '2rem', maxWidth: '1200px', margin: 'auto' }}>
      <Box sx={{ p: 3, boxShadow: 3, borderRadius: 2, border: '1px solid', borderColor: '#FFA500' }}>
        <Typography variant="h4" gutterBottom color="#FFA500">Order Details</Typography>
        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="body1">
            <strong>Order ID:</strong> {rowData.orderId} &nbsp;
            <strong>UID:</strong> {rowData?.uid} &nbsp;
            <strong>Vehicle Number:</strong> {rowData.vehicleNo} &nbsp;
            <strong>Date:</strong> {formattedDate}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom color="textPrimary">Customer Details</Typography>
          <Typography variant="body1">
            <strong>Phone:</strong> {rowData.phone}
          </Typography>
          <Typography variant="body1">
            <strong>Name:</strong> {rowData.fullName}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {rowData.email}
          </Typography>
          <Typography variant="body1">
            <strong>Address:</strong><br />
            Street Address: {rowData.stAdress}<br />
            Flat No, House: {rowData.house}<br />
            City: {rowData.city}<br />
            PinCode: {rowData.pinCode}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          {uidInputVisible && (
            <Box sx={{ mb: 2 }}>
              <TextField
                label="UID of QR Code"
                value={uid}
                onChange={(e) => setUid(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
              {success && (
                <Typography variant="body1" color="success.main">
                  UID Updated Successfully
                </Typography>
              )}
              <Button
                variant="contained"
                color="#FFA500"
                onClick={handleStatusChange}
                sx={{ mt: 2 }}
              >
                Save
              </Button>
            </Box>
          )}
          <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={selectedStatus || rowData.orderStatus}
                onChange={handleStatusChange}
                label="Status"
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Order Received">Order Received</MenuItem>
                <MenuItem value="Order Dispatched">Order Dispatched</MenuItem>
                <MenuItem value="Order Delivered">Order Delivered</MenuItem>
                <MenuItem value="Activated">Activated</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SingleRow;
