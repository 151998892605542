import { useParams } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { VendorContext } from "../../context/vendorContext";
import { useNavigate } from "react-router-dom";
import Dashcopy from "../Dashcopy/Dashcopy";
import Sidebar from "../Sidebar/Sidebar";
import Dashnavbar from "../DashNav/Dashnavbar";
import EditIcon from "@mui/icons-material/Edit";

export default function EditVendor() {
    const navigate = useNavigate();
    const { vendorData } = useContext(VendorContext);
    const [vendor, setVendor] = useState({});
    const { vendorid } = useParams();
    let baseUrl;
    if (process.env.NODE_ENV === "development") {
        baseUrl = process.env.REACT_APP_BACKEND_LOCALAPI;
    } else {
        baseUrl = process.env.REACT_APP_BACKEND_LIVEAPI;
    }

    useEffect(() => {
        if (vendorData && vendorData.length > 0) {
            const foundVendor = Object.values(vendorData)
                .flat()
                .find((vendor) => vendor._id === vendorid);
            if (foundVendor) {
                setVendor(foundVendor);
            }
        }
    }, [vendorData, vendorid]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const bearerToken = localStorage.getItem("token");

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
        };

        const requestOptions = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(vendor),
        };

        try {
            const response = await fetch(
                `${baseUrl}/vendors/${vendorid}`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Failed to update vendor data");
            }

            navigate("/dashboard/vendors");
        } catch (error) {
            console.error("Error updating vendor data:", error.message);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setVendor((prevVendor) => ({
            ...prevVendor,
            [name]: value,
        }));
    };

    const vendorName = vendor?.vendorName || "";
    const vendorDescription = vendor?.vendorDescription || "";
    const contactEmail = vendor?.contactEmail || "";
    const contactPhone = vendor?.contactPhone || "";
    const addressStreet = vendor?.addressStreet || "";
    const addressCity = vendor?.addressCity || "";
    const addressState = vendor?.addressState || "";

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Dashnavbar />
                <div className="p-0 ml-5">
                    <h1 className="text-2xl font-roboto tracking-wide">
                        <EditIcon /> Update Vendor Details
                    </h1>
                </div>

                <div className="bottom">
                    <div className="right">
                        <div className="container w-full">
                            <form onSubmit={handleSubmit} className="ml-5 mt-8 p-10 rounded">
                                <div className="flex flex-wrap gap-0 w-full">
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="vendorName">
                                            Vendor Name
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            id="vendorName"
                                            name="vendorName"
                                            value={vendorName}
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Vendor Name"
                                        />
                                    </div>
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="contactEmail">
                                            Contact Email
                                        </label>
                                        <input
                                            className="w-90 rounded formInput mr-10"
                                            type="email"
                                            placeholder="Contact Email"
                                            onChange={handleChange}
                                            id="contactEmail"
                                            name="contactEmail"
                                            value={contactEmail}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-0 w-full mt-3">
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="contactPhone">
                                            Contact Phone
                                        </label>
                                        <input
                                            className="w-90 rounded formInput mr-10"
                                            type="tel"
                                            placeholder="Contact Phone"
                                            onChange={handleChange}
                                            id="contactPhone"
                                            name="contactPhone"
                                            value={contactPhone}
                                        />
                                    </div>
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="addressStreet">
                                            Address Street
                                        </label>
                                        <input
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Address Street"
                                            onChange={handleChange}
                                            id="addressStreet"
                                            name="addressStreet"
                                            value={addressStreet}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-0 w-full mt-3">
                                <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="vendorDescription">
                                            Description
                                        </label>
                                        <input
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="Description"
                                            onChange={handleChange}
                                            id="addressState"
                                            name="vendorDescription"
                                            value={vendorDescription}
                                        />
                                    </div>
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="addressCity">
                                            City
                                        </label>
                                        <input
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="City"
                                            onChange={handleChange}
                                            id="addressCity"
                                            name="addressCity"
                                            value={addressCity}
                                        />
                                    </div>


                                </div>
                                <div className="flex flex-wrap gap-0 w-full mt-3">
                                   
                                    <div className="lg:w-1/2 flex flex-col">
                                        <label className="mb-2 text-lg" htmlFor="addressState">
                                            State
                                        </label>
                                        <input
                                            className="w-90 rounded formInput mr-10"
                                            type="text"
                                            placeholder="State"
                                            onChange={handleChange}
                                            id="addressState"
                                            name="addressState"
                                            value={addressState}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end mt-10">
                                    <button
                                        type="submit"
                                        className="w-40 mr-8 bg-black hover:bg-logoClr text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Update Now
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center pb-2 mr-10 absolute bottom-0 right-10">
                    <Dashcopy />
                </div>
            </div>
        </div>
    );
}
