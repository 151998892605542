

import React, { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../context/OrderContext";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { PackageContext } from "../../context/packageContext";
import { CustomerContext } from "../../context/customrContext";
import TableCell from "@mui/material/TableCell";
import loadingGIf from "../../images/loading.gif";
import { format } from "date-fns";
import { exportToExcel } from "../../utils/exportUtils";

const OrderTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { packageData } = useContext(PackageContext);
  const { orderData, qrCodeData } = useContext(OrderContext);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [filterModel, setFilterModel] = useState({ items: [] });

  useEffect(() => {
    if (qrCodeData && orderData) {
      setIsLoading(false);
    }
  }, [orderData, qrCodeData]);


  if (isLoading) {
    <div className="flex justify-center items-center">
      <img className="w-32 h-32" src={loadingGIf} alt="loading_gif" />
    </div>;
  }
  const handleRowClick = (params) => {
    const selectedId = params.row.id;
    setSelectedRowId(selectedId);
    navigate("/dashboard/single-row", { state: { rowData: params.row } });
  };




  const handleExport = () => {
    if (!orderData) {
      console.warn('Order data is not available');
      return;
    }

    const filteredRows = (orderData || [])
      .filter(order => {
        return filterModel.items?.every(filter => {
          const { columnField, operatorValue, value } = filter;
          const fieldValue = order[columnField]?.toString().toLowerCase() || '';

          if (!value) return true;
          if (operatorValue === 'contains') {
            return fieldValue.includes(value.toString().toLowerCase());
          } else if (operatorValue === 'equals') {
            return fieldValue === value.toString().toLowerCase();
          }
          return true;
        });
      })
      .map(order => {
        return {
          orderId: order.orderId || 'N/A',
          Date: order.createdAt ? format(new Date(order.createdAt), 'dd-MM-yyyy') : 'N/A',
          paymentStatus: order.paymentStatus || 'N/A',
          orderStatus: order.orderStatus || 'N/A',
          referralCode: order.referCode || 'N/A',
          UID: order.uid || 'N/A',
          mobileNumber: order.phone || 'N/A',
          customerName: order.fullName || 'N/A',
          vehicleNumbers: order.vehicleNo || 'N/A',
          deliveryAddress: `${order.stAdress || ''} ,${order.stateAddress || ''} ,${order.city || ''} ,${order.house || ''}`.trim(),
          pinCodes: order.pinCode || 'N/A',
        };
      });

    if (filteredRows.length === 0) {
      console.warn('No data available for export');
      return;
    }
    console.log('Filtered Rows:', filteredRows);
    exportToExcel(filteredRows, 'OrderData.xlsx');
  };





  const rows = (orderData || [])
    .map((order) => ({
      id: order._id,
      ...order,
    }))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


  const cartColumns = [
    {
      field: "orderId",
      headerName: "Order ID",
      width: 70,
      renderCell: (params) => (
        <div
          className="underline cursor-pointer font-normal
         text-bgnavclr hover:font-extrabold"
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Order Date',
      width: 110,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = format(date, 'dd-MM-yyyy');
        const formattedTime = format(date, 'HH:mm:ss');

        return (
          <div class="flex flex-col items-center  ">
            <div class="text-xs font-semibold ">
              {`Date: ${formattedDate}`}
            </div>
            {/* <div class="text-xs font-semibold ">
              {`Time: ${formattedTime}`}
            </div> */}
          </div>

        );
      }
    }, {
      field: "paymentStatus",
      headerName: "Pyament  Status",
      width: 120,
      renderCell: (params) => (

        <div
          className="cursor-pointer font-normal
         text-bgnavclr hover:font-extrabold"
        >
          {params.value}
        </div>
      ),
    }, {
      field: "orderStatus",
      headerName: "Order Status",
      width: 110,
      renderCell: (params) => (
        <div
          className="cursor-pointer font-normal
         text-black hover:font-bold"
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "referCode",
      headerName: "Referral Code",
      width: 100,
      renderCell: (params) => (
        <div
          className="cursor-pointer font-normal
         text-black hover:font-bold"
        >
          {params.value || 'VTCODE'}
        </div>
      ),
    },
    {
      field: "uid",
      headerName: "UID",
      width: 80,
      renderCell: (params) => {
        // const orderId = params;
        // const getUid = qrCodeData.find((qrCode) => qrCode.orderId === orderId);
        return (
          <>

            <div >
              {params.value || 'N/A'}
            </div>
          </>
        );
      },
    },
    {
      field: "phone",
      headerName: "Mobile Number",
      width: 100,
      renderCell: (params) => {

        return (
          <ul className="flex">
            {params.value}
          </ul>
        );
      },
    },
    {
      field: "fullName",
      headerName: "Customer Name",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="flex">
            <p>{params.value}</p>
          </div>
        );
      },
    }
    ,

    {
      field: "vehicleNo",
      headerName: "Vehicle number",
      width: 130,
      renderCell: (params) => {

        return (
          <>
            <p>{params.value}</p>
          </>
        );
      },
    },
    {
      field: "Address",
      headerName: "Delivery Address",
      width: 120,
      renderCell: (params) => {
        const address = params.row; // Use `params.row` to access the data for this row
        return (
          <div key={address._id}>
            <p>
              {address.stAdress} {address.city} {address.house}
            </p>
          </div>
        );
      },
    },

    {
      field: "rows",
      headerName: "PinCode",
      width: 80,
      renderCell: (params) => {
        const address = params.row;
        return (
          <>
            <p>{address.pinCode}</p>
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <TableCell>
          {/* <div className="cellAction">
            <div
              className="deleteButton" onClick={() => deleteCartItem(params.row._id)}
            >
              Delete
            </div>
          </div> */}
        </TableCell>
      ),
    },
  ];
  return (
    <div className="datatable font-serif text-sm ">
      <div className="datatableTitle">
        <h2 className="text-2xl font-serif font-extraligh tracking-wider leading-10 text-main">
          Cart Items
        </h2>
        <button
          onClick={handleExport}
          className="export-button bg-logoClr text-white p-2 px-3 rounded ml-4 font-roboto"
        >
          Download
        </button>
      </div>


      <DataGrid
        className="datagrid"
        rows={rows}
        columns={cartColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onRowClick={handleRowClick}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}

      />

    </div>
  );
};

export default OrderTable;
