export const subColumns = [
  { field: "id", headerName: "ID", width: 70 },

  {
    field: "username",
    headerName: "Customer Name",
    width: 230,
  },
  {
    field: "email",
    headerName: "Gmail",
    width: 230,
  },
  {
    field: "mobileNumber",
    width: 200,
    headerName: 'Phone ',

  }
];


export const subRows = {

}